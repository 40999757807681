import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  max-width: 100vw;
  overflow: hidden;
`

export const ImageWrapper = styled.div``

export const Columns = styled.div`
  ${tw`grid grid-cols-3 gap-x-8 px-insetX items-center ta-max-width`};
`

export const Collage = styled.div`
  ${tw`grid gap-4 px-insetX items-center`};
  grid-template-areas:
    'one two'
    'one three';
  grid-template-columns: minmax(0, 68%) minmax(0, 32%);

  & > *:first-child {
    grid-area: one;
  }

  & > *:nth-child(2) {
    grid-area: two;
  }

  & > *:nth-child(3) {
    grid-area: three;
  }
`

const sideBySideGridTemplateColumns = ({ $focus }) => {
  if ($focus == 'left') {
    return 'minmax(0, 57%) minmax(0, 43%)'
  } else if ($focus == 'right') {
    return 'minmax(0, 43%) minmax(0, 57%)'
  } else {
    return 'minmax(0, 50%) minmax(0, 50%)'
  }
}

export const SideBySide = styled.div`
  ${tw`grid gap-x-8 items-center px-insetX ta-max-width`};
  grid-template-columns: ${sideBySideGridTemplateColumns};
`
