import Image from 'next/image'

import { isPresent } from 'utils'

import Button from 'components/Button'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { BlurUp, ImageWrapper, Inner, Main, Root } from './ImageAndTextSlice.style'

const blurUp = (src) => {
  return `${src}&w=100&blur=600&auto=format,compress`.replace(
    'cdn.shopify.com/s/files',
    'the-armoury-shopify.imgix.net'
  )
}

const loader = ({ src, width, quality }) => {
  // TODO handle 'original' as crop value. No `ar` param in URL
  return `${src}&w=${width}&q=${quality || 75}&fit=crop&auto=format,compress`.replace(
    'cdn.shopify.com/s/files',
    'the-armoury-prismic.imgix.net'
  )
}

const ImageAndTextSlice = ({
  design,
  header,
  text,
  imageUrl,
  imageWidth,
  imageHeight,
  buttonText,
  buttonLink,
  reverse,
  align,
}) => {
  return (
    <Root $design={design}>
      <Inner $reverse={reverse}>
        <ImageWrapper>
          <BlurUp src={blurUp(imageUrl)} alt="" />
          <Image
            src={imageUrl}
            width={imageWidth}
            height={imageHeight}
            alt=""
            loader={loader}
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </ImageWrapper>
        <Main $reverse={reverse} $align={align}>
          <Header size="sm">{header}</Header>
          <Spacer y={8} />
          <Text html={text} />
          <Spacer y={8} />
          {isPresent(buttonLink) && isPresent(buttonText) && (
            <Button as="a" href={buttonLink} text={buttonText} variant="outlined" />
          )}
        </Main>
      </Inner>
    </Root>
  )
}

ImageAndTextSlice.propTypes = {}

ImageAndTextSlice.defaultProps = {}

export default ImageAndTextSlice
