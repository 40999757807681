import styled from 'styled-components'
import tw from 'twin.macro'

export const SwiperWrapper = styled.div`
  ${tw`select-none max-w-watchesMax mx-auto`};
  padding-left: 50px;
  padding-right: 50px;

  .swiper {
    ${tw`overflow-visible`};
  }
`

export const FullScreenSwiperWrapper = styled.div`
  ${tw`relative select-none w-screen h-screen overflow-hidden`};

  overflow: hidden;
  overscroll-behavior: none;

  --swiper-navigation-color: var(--color-white);
  --swiper-navigation-size: 2rem;

  @media (min-width: 768px) {
    --swiper-navigation-size: 2.5rem;
  }

  .swiper {
    width: 100% !important;
    height: 100% !important;
  }

  .swiper-slide {
    ${tw`relative`};
  }

  .swiper-slide img {
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }

  /* Well, this is annoying */
  .swiper-slide > * > * {
    ${tw`h-screen p-4`};
  }

  ${{
    position: 'relative',
    paddingBottom: 'env(safe-area-inset-bottom)',
    display: 'grid',
    minWidth: 0,
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
  }};

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0rem;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    padding: 1rem;
    background-color: var(--color-gold);
    margin-top: 0;
    transform: translateY(-50%);
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: calc(var(--swiper-navigation-size) * 0.38);
  }

  .swiper-button-prev:after {
    margin-right: 0.1em;
  }

  .swiper-button-next:after {
    margin-left: 0.1em;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
    background-color: var(--color-grey);
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }
`

export const Overlay = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full z-40 bg-overlay-bg`}
`

export const FullScreenToggle = styled.button`
  ${tw`absolute top-4 right-4 z-10 flex items-center justify-center`};
  ${tw`fixed top-4 right-4 z-50`};

  border-color: var(--color-gold);
  background-color: var(--color-gold);
  border-radius: 3px;
  --toggle-size: 2rem;

  @media (min-width: 768px) {
    --toggle-size: 2.5rem;
  }

  width: var(--toggle-size);
  height: var(--toggle-size);

  svg * {
    fill: var(--color-white);
  }
`
