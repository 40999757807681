import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX`};
  ${({ $design }) => $design !== 'inset_with_background' && tw`ta-max-width-parent`};
  ${({ $design }) => $design === 'inset_with_background' && tw`p-8 md:p-16 ta-max-width-parent`};
  ${({ $design }) => $design === 'inset_with_background' && { backgroundColor: 'var(--color-gold-white)' }};
`

export const Inner = styled.div`
  ${tw`grid items-center gap-x-8`};
  background-color: var(--color-white);

  @media (min-width: 48rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* @media (min-width: 62rem) {
    grid-template-columns: ${({ $reverse }) =>
    $reverse ? 'minmax(0, 38%) minmax(0, 62%)' : 'minmax(0, 62%) minmax(0, 38%)'};
  } */
  @media (min-width: 62rem) {
    grid-template-columns: ${({ $reverse }) =>
      $reverse ? 'minmax(0, 43%) minmax(0, 57%)' : 'minmax(0, 57%) minmax(0, 43%)'};
  }
`

const align = ({ $align }) => {
  if ($align === 'left') {
    return tw`items-start text-left`
  }

  return tw`items-center text-center`
}

export const Main = styled.div`
  ${tw`p-8 sm:p-12 flex flex-col justify-center`};
  ${({ $reverse }) => $reverse && tw`order-first`};
  color: var(--color-gold-black);
  background-color: var(--color-white);
  ${align};
`

export const ImageWrapper = styled.div`
  position: relative;
`

export const BlurUp = styled.img`
  ${tw`absolute top-0 left-0 w-full h-full`};
  z-index: -1;
`
