import Image from 'next/image'
import PropTypes from 'prop-types'

import { isBlank, isPresent } from 'utils'

import Text from 'components/Text'

import { BlurUp, Caption, ImageWrapper, Root } from './FancyImage.style'

const ratioFromCrop = (crop) => {
  switch (crop) {
    case 'portrait':
      return '4:6'
    case 'landscape':
      return '6:4'
    case 'letterbox':
      return '21:9'
    case 'square':
      return '1:1'
  }
}

const FancyImage = ({ url, width, height: heightProp, caption, crop, alt, sizes = '100vw' }) => {
  const ratio = ratioFromCrop(crop)

  const orientation = ratio === '4:6' ? 'portrait' : 'landscape'

  const blurUp = (src) => {
    return `${src}&w=100&blur=600&ar=${ratio}&auto=format,compress`.replace(
      'cdn.shopify.com/s/files',
      'the-armoury-shopify.imgix.net'
    )
  }

  const loader = ({ src, width, quality }) => {
    // TODO handle 'original' as crop value. No `ar` param in URL
    return `${src}&w=${width}&q=${quality || 75}&ar=${ratio}&fit=crop&auto=format,compress`.replace(
      'cdn.shopify.com/s/files',
      'the-armoury-prismic.imgix.net'
    )
  }

  const height =
    {
      '6:4': width * 0.66,
      '4:6': width * 1.5,
      '21:9': 171, // Really?
      '1:1': width,
    }[ratio] || heightProp

  if (isBlank(url)) return null

  return (
    <Root $orientation={orientation} $hasCaption={isPresent(caption)}>
      <ImageWrapper>
        <BlurUp src={blurUp(url)} alt="" />
        <Image
          loader={loader}
          src={url}
          width={width}
          height={height}
          alt={alt}
          sizes={sizes}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </ImageWrapper>
      {isPresent(caption) && (
        <Caption>
          <Text html={caption} />
        </Caption>
      )}
    </Root>
  )
}

FancyImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  crop: PropTypes.oneOf(['portrait', 'landscape', 'letterbox', 'square']),
}

FancyImage.defaultProps = {}

export default FancyImage
