import Image from 'next/image'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FreeMode, Navigation, Pagination, Scrollbar, Thumbs, Zoom } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/zoom'
import { Swiper, SwiperSlide } from 'swiper/react'

import { isPresent } from 'utils'

import Button from 'components/Button'
import FancyImage from 'components/FancyImage'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'
import Text from 'components/Text'

import {
  Body,
  Buttons,
  Cover,
  CoverButton,
  FullScreenSwiperWrapper,
  FullScreenToggle,
  Main,
  Overlay,
  Root,
  TextWrapper,
} from './GallerySlice.style'
import MinSvgA from './svg/min.svg'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}`
}

const GallerySlice = ({
  subheader,
  header,
  body,
  backgroundColor,
  textColor,
  images,
  secondaryButtonLink,
  secondaryButtonText,
  primaryButtonLink,
  primaryButtonText,
}) => {
  const [fullscreen, setFullscreen] = useState(false)
  const coverImage = images?.[0]

  return (
    <>
      {fullscreen && (
        <FullScreenToggle onClick={() => setFullscreen(false)} title={'Exit full screen'}>
          <MinSvgA width={20} height={20} />
        </FullScreenToggle>
      )}
      {fullscreen && (
        <Overlay>
          <FullScreenSwiperWrapper>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              modules={[FreeMode, Navigation, Thumbs, Scrollbar, Zoom, Pagination]}
              navigation
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <Image
                    loader={loader}
                    src={image?.url}
                    alt={image?.alt}
                    width={image.width}
                    height={image.height}
                    layout="responsive"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </FullScreenSwiperWrapper>
        </Overlay>
      )}
      <Root>
        <Main>
          <Cover onClick={() => setFullscreen(true)}>
            {coverImage && (
              <Image
                loader={loader}
                src={coverImage?.url}
                alt={coverImage?.alt}
                fill={true}
                sizes="(min-width: 48rem) 50w, 100w"
              />
            )}
            <CoverButton>
              <Button
                as="a"
                onClick={() => setFullscreen(true)}
                text="Browse the gallery"
                variant="outlined"
                $backgroundColor="var(--color-white)"
              />
            </CoverButton>
          </Cover>
          <Body $backgroundColor={backgroundColor} $textColor={textColor}>
            {isPresent(subheader) && (
              <>
                <SubHeader color={textColor}>{subheader}</SubHeader>
                <Spacer y={1} />
              </>
            )}
            {isPresent(header) && <Header color={textColor}>{header}</Header>}
            <Spacer y={4} />
            <TextWrapper>
              <Text html={body} color={textColor} />
            </TextWrapper>
            <Spacer y={12} />
            <Buttons>
              {isPresent(secondaryButtonLink) && isPresent(secondaryButtonText) && (
                <Button
                  as="a"
                  href={secondaryButtonLink}
                  text={secondaryButtonText}
                  variant="outlined"
                  width="full"
                  $backgroundColor={textColor}
                />
              )}
              <Spacer y={4} />
              {isPresent(primaryButtonLink) && isPresent(primaryButtonText) && (
                <Button
                  as="a"
                  href={primaryButtonLink}
                  text={primaryButtonText}
                  width="full"
                  $backgroundColor={textColor}
                />
              )}
            </Buttons>
          </Body>
        </Main>
      </Root>
    </>
  )
}

GallerySlice.propTypes = {
  subheader: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  images: PropTypes.array,
}

GallerySlice.defaultProps = {
  backgroundColor: 'var(--color-white)',
  textColor: 'var(--color-text)',
  images: [],
}

export default GallerySlice
