import { format, isSameDay, isSameMonth, isSameYear, parseISO } from 'date-fns'
import PropTypes from 'prop-types'

import { isBlank, isPresent } from 'utils'

import { Root } from './FormattedDateRange.style'

const formatDate = function (date, preset) {
  switch (preset) {
    case 'month':
      return format(date, 'MMMM')
    case 'year':
      return format(date, 'y')
    case 'day':
      return format(date, 'E d')
    default:
      return isSameYear(new Date(), date) ? format(date, 'E d MMMM') : format(date, 'E d MMMM y')
  }
}

const FormattedDateRange = ({ start, end }) => {
  if (isPresent(start) && isBlank(end)) {
    try {
      const startDate = parseISO(start)
      return <Root>{formatDate(startDate)}</Root>
    } catch (e) {
      console.error(e)
      return null
    }
  }

  try {
    const startDate = parseISO(start)
    const endDate = parseISO(end)
    const sameDay = isSameDay(startDate, endDate)
    const sameMonth = isSameMonth(startDate, endDate)
    const sameYear = isSameYear(startDate, endDate)
    const thisYear = isSameYear(new Date(), endDate)

    if (sameDay) return <Root>{formatDate(startDate)}</Root>

    return (
      <Root>
        {formatDate(startDate, 'day')} {!sameMonth && formatDate(startDate, 'month')}{' '}
        {!sameYear && formatDate(startDate, 'year')}
        {' — '}
        {formatDate(endDate, 'day')} {formatDate(endDate, 'month')} {!sameYear && formatDate(endDate, 'year')}
        {!thisYear && formatDate(endDate, 'year')}
      </Root>
    )
  } catch (e) {
    console.error(e)
    return null
  }
}

FormattedDateRange.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
}

FormattedDateRange.defaultProps = {}

export default FormattedDateRange
