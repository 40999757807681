import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import FancyImage from 'components/FancyImage'
import ImageCarousel from 'components/ImageCarousel'

import { Collage, Columns, ImageWrapper, Root, SideBySide } from './ImagesSlice.style'

const ComponentsForLayouts = {
  columns: Columns,
  collage: Collage,
  carousel: ImageCarousel,
  'side-by-side': SideBySide,
}

const sizesForLayouts = {
  columns: '33vw',
  collage: ['68vw', '32vw', '32vw'],
  'side-by-side': {
    left: ['57vw', '43vw'],
    right: ['43vw', '57vw'],
    center: ['50vw', '50vw'],
  },
}

const imagesForLayouts = {
  columns: 3,
  collage: 3,
  'side-by-side': 2,
}

const ImagesSlice = ({ layout, focus, images: imagesProp }) => {
  const images = imagesForLayouts[layout] ? imagesProp.slice(0, imagesForLayouts[layout]) : imagesProp
  const Component = ComponentsForLayouts[layout]
  const sizes = layout === 'side-by-side' ? sizesForLayouts[layout][focus] : sizesForLayouts[layout]

  if (isBlank(images)) return null

  return (
    <Root>
      <Component $layout={layout} $focus={focus}>
        {images.map((image, index) => (
          <ImageWrapper key={index}>
            <FancyImage {...image} sizes={Array.isArray(sizes) ? sizes[index] : sizes} />
          </ImageWrapper>
        ))}
      </Component>
    </Root>
  )
}

ImagesSlice.propTypes = {
  layout: PropTypes.oneOf(['columns', 'collage', 'carousel', 'side-by-side']),
}

ImagesSlice.defaultProps = {}

export default ImagesSlice
