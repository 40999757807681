import { useResizeObserver } from '@mantine/hooks'
import { contain } from 'intrinsic-scale'
import Image from 'next/image'

import Text from 'components/Text'

import { BlurUp, Caption, ImageContainer, Root, Wrapper } from './ImageSlice.style'

const ratioFromCrop = (crop) => {
  switch (crop) {
    case 'portrait':
      return '4:6'
    case 'landscape':
      return '6:4'
    case 'letterbox':
      return '21:9'
    case 'square':
      return '1:1'
  }
}

const ImageSlice = ({ imageUrl, imageWidth, imageHeight, crop, caption, width }) => {
  const ratio = ratioFromCrop(crop)
  const [ref, dimensions] = useResizeObserver()

  const { width: containWidth } = contain(dimensions?.width, dimensions?.height, imageWidth, imageHeight)

  const orientation = ratio === '4:6' ? 'portrait' : 'landscape'

  const blurUp = (src) => {
    return `${src}&w=100&blur=600&ar=${ratio}&auto=format,compress`.replace(
      'cdn.shopify.com/s/files',
      'the-armoury-shopify.imgix.net'
    )
  }

  const loader = ({ src, width, quality }) => {
    // TODO handle 'original' as crop value. No `ar` param in URL
    return `${src}&w=${width}&q=${quality || 75}&ar=${ratio}&fit=crop&auto=format,compress`.replace(
      'cdn.shopify.com/s/files',
      'the-armoury-prismic.imgix.net'
    )
  }

  const height =
    {
      '6:4': imageWidth * 0.66,
      '4:6': imageWidth * 1.5,
      '21:9': 171, // Really?
      '1:1': imageWidth,
    }[ratio] || imageHeight

  return (
    <Wrapper $width={width}>
      <Root $orientation={orientation} $width={width}>
        <ImageContainer ref={ref}>
          {/* <BlurUp
            src={blurUp(imageUrl)}
            width={imageWidth}
            height={height}
            alt=""
            style={{
              width: '100%',
              height: '100%',
            }}
          /> */}
          <Image
            loader={loader}
            src={imageUrl}
            width={imageWidth}
            height={height}
            alt=""
            sizes="100vw"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </ImageContainer>
        <Caption $widthPx={containWidth} $width={width}>
          <Text>{caption}</Text>
        </Caption>
      </Root>
    </Wrapper>
  )
}

ImageSlice.propTypes = {}

ImageSlice.defaultProps = {}

export default ImageSlice
