import { useState } from 'react'
import Fade from 'react-reveal/Fade'
import { FreeMode, Navigation, Pagination, Scrollbar, Thumbs, Zoom } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/zoom'
import { Swiper, SwiperSlide } from 'swiper/react'

import { FullScreenSwiperWrapper, FullScreenToggle, Overlay, SwiperWrapper } from './ImageCarousel.style'
import MinSvgA from './svg/min.svg'

const ImageCarousel = ({ children }) => {
  const [fullscreen, setFullscreen] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const handleSlideClick = (index) => {
    setCurrentSlide(index)
    setFullscreen(true)
  }

  if (!children) return null

  return (
    <>
      {fullscreen && (
        <FullScreenToggle onClick={() => setFullscreen(false)} title={'Exit full screen'}>
          <MinSvgA width={20} height={20} />
        </FullScreenToggle>
      )}
      <Fade right distance="50px" duration={1500}>
        <SwiperWrapper>
          <Swiper
            spaceBetween={20}
            centeredSlidesBounds
            centerInsufficientSlides
            slidesPerView={1}
            breakpoints={{
              600: {
                slidesPerView: 2,
              },
              800: {
                slidesPerView: 3,
              },
            }}
          >
            {children.map((image, index) => (
              <SwiperSlide onClick={() => handleSlideClick(index)} key={index}>
                {image}
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
      </Fade>
      {fullscreen && (
        <Overlay>
          <FullScreenSwiperWrapper>
            <Swiper
              initialSlide={currentSlide}
              spaceBetween={0}
              slidesPerView={1}
              modules={[FreeMode, Navigation, Thumbs, Scrollbar, Zoom, Pagination]}
              navigation
            >
              {children.map((image, index) => (
                <SwiperSlide key={index}>{image}</SwiperSlide>
              ))}
            </Swiper>
          </FullScreenSwiperWrapper>
        </Overlay>
      )}
    </>
  )
}

ImageCarousel.propTypes = {}

ImageCarousel.defaultProps = {}

export default ImageCarousel
