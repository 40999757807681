import Head from 'next/head'

import { isBlank, isPresent } from 'utils'

const PrismicPageHead = ({ document }) => {
  if (isBlank(document)) return null

  const { seo_title, seo_description } = document.data

  return (
    <Head>
      {isPresent(seo_title) && <title>{seo_title}</title>}
      {isPresent(seo_description) && <meta name="description" content={seo_description} />}
    </Head>
  )
}

PrismicPageHead.propTypes = {}

PrismicPageHead.defaultProps = {}

export default PrismicPageHead
