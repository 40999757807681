import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  position: relative;
  display: grid;
  ${({ $hasCaption }) =>
    $hasCaption
      ? {
          gridTemplateRows: 'minmax(0, 1fr) minmax(0, min-content)',
        }
      : {
          gridTemplateRows: 'minmax(0, 1fr)',
          gridTemplateColumns: 'minmax(0, 1fr)',
        }};

  img {
    object-fit: contain;
  }
`

export const Caption = styled.div`
  ${tw`pt-2`};
`

export const ImageWrapper = styled.div`
  ${tw`relative`};
`

export const BlurUp = styled.img`
  ${tw`absolute top-0 left-0 w-full h-full`};
  z-index: -1;
`
